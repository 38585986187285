<template>
  <div class="forgetpwd">
    <van-nav-bar
        title="重置密码"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="tel"
        ref="mobile"
        type="tel"
        name="tel"
        label="+86"
        placeholder="输入手机号码"
        :rules="[{ validator, message: '请输入手机号' }]"
      />
      <van-field
        v-model="code"
        type="tel"
        name="code"
        label="验证码"
        placeholder="验证码"
        :rules="[{ required: true, message: '请填写密码' }]"
      >
        <template #button>
          <van-button
            native-type="button"
            size="mini"
            :disabled="btnDisabled"
            type="danger"
            @click="sendcode"
          >
            <van-count-down v-show="!showTxt" :time="time" @finish="finish" ref="daojishi">
              <template v-slot="timeData">
                <span class="seconds">{{ timeData.seconds }}后重新发送</span>
              </template>
            </van-count-down>
            <span v-show="showTxt">发送验证码</span>
          </van-button>
        </template>
      </van-field>
      <van-field
        v-model="password1"
        type="password"
        name="pwd1"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <van-field
        v-model="password2"
        type="password"
        name="pwd2"
        label="重复密码"
        placeholder="再输入一遍密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="danger" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "Forgetpwd",
  data() {
    return {
      tel: '', 
      password1: '',
      password2: '',
      btnDisabled: true, 
      code: '', // 验证码
      showTxt: false,
      time: 0,
      shareid: 0,
      merchid: 0,
    };
  },
  mounted() {
    this.tel = this.$route.query.tel
    if (this.tel) {
      this.btnDisabled = false;
    }else{
      this.btnDisabled = true;
    }

    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, "重置密码");
  },
  methods: {
    validator(val) {
      return /1\d{10}/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },
    sendcode(){
      let _that = null;
      _that = this;
      if (_that.tel && _that.validator(_that.tel)) {
        _that.$axios.post(
          _that.$store.state.domain + "web/user/send_sms",
          _that.$qs.stringify({
            tel: _that.tel,
            type: 'login'
          })
        )
        _that.$refs.daojishi.reset();
        _that.time = 60 * 1000;
        _that.btnDisabled = true;
        _that.showTxt = false;  
      }else{
        _that.$refs.mobile.focus();
        _that.$notify('请输入正确的手机号');
      }
    },
    finish(){
      this.btnDisabled = false;
      this.showTxt = true;
    },
    onSubmit() {
      let _that = null;
      _that = this;
      _that.$axios.post(
        _that.$store.state.domain + "web/user/rest_pwd",
        _that.$qs.stringify({
          tel: _that.tel,
          code: _that.code,
          pwd1: _that.password1,
          pwd2: _that.password2
        })
      ).then(function(response){
        console.log(response);
        if (response.data && response.data.code == 100000) {
          _that.$notify({
            type: 'success',
            message: response.data.msg ? response.data.msg : '密码重置成功'
          });
          setTimeout(()=>{
            _that.$router.go(-1);
          },2000)
        }else{
          _that.$notify({
            type: 'warning',
            message: response.data && response.data.msg ? response.data.msg : '密码重置失败'
          });
        }
      }).catch(function(error){
        console.log(error);
      })
    },
    onClickLeft(){
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="less">
.forgetpwd {
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 2;
  text-align: left;
  .van-nav-bar {
    .van-icon{
        color: #333;
    }
  }
  .van-form {
    margin-top: 30px;
    .van-cell{
        padding: 10px 20px;
        .van-field__label {
          width: 70px;
        }
        .van-cell__value{
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
            
          .van-field__button{
            height: 24px;
            line-height: 24px;
            .van-button::before{
              border: 0;
            }
            .van-button--default{
              border: 0;
            }
            .van-button--small{
              height: 24px;
            }
          }
        }
        .seconds{
            color: #fff;
            font-size: 12px;
        }
    }
  }
}
</style>